exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-ecosystem-ecosystem-json-slug-js": () => import("./../../../src/pages/ecosystem/{ecosystemJson.slug}.js" /* webpackChunkName: "component---src-pages-ecosystem-ecosystem-json-slug-js" */),
  "component---src-pages-ecosystem-index-js": () => import("./../../../src/pages/ecosystem/index.js" /* webpackChunkName: "component---src-pages-ecosystem-index-js" */),
  "component---src-pages-governance-js": () => import("./../../../src/pages/governance.js" /* webpackChunkName: "component---src-pages-governance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investor-relations-js": () => import("./../../../src/pages/investor-relations.js" /* webpackChunkName: "component---src-pages-investor-relations-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-news-news-detail-js": () => import("./../../../src/pages/news/newsDetail.js" /* webpackChunkName: "component---src-pages-news-news-detail-js" */)
}

